// import "@babel/polyfill";
// import "mutationobserver-shim";
import Vue from 'vue'
import App from './App.vue'
import ApiService from './services/api.service'

import i18n from './plugins/i18n'
import router from './plugins/router'
import store from './plugins/store'

import './plugins/googleanalytics'

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_ROOT_API)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
