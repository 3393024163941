<template>
  <component :is="layout">
    <router-view :layout.sync="layout" />
  </component>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      layout: 'div',
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Satisfy&display=swap');
@import '@/styles/bootstrap-imports.scss';
@import '~bootstrap-vue/src/index.scss';
@import '@/styles/custom.scss';
@import '@/styles/wp-custom.scss';

html,
body {
  height: 100%;
}

// #app {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
// }
</style>
