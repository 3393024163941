import { setup } from 'axios-cache-adapter'

class ApiError extends Error {
  constructor(errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

const ApiService = {
  api: null,

  init(baseURL) {
    this.api = setup({
      baseURL: baseURL,
      headers: {},
      cache: {
        maxAge: 15 * 60 * 1000,
        exclude: {
          // Store responses from requests with query parameters in cache
          query: false,
        },
      },
    })
  },

  async get(resource, config = {}) {
    if (sessionStorage.token) {
      // set authorization
      if (!config.headers) config.headers = {}
      config.headers.authorization = 'Bearer ' + sessionStorage.token

      // set preview status
      if (!config.params) config.params = {}
      config.params.status = 'any'
      console.log('PARMS: ', config)
    }
    var response = await this.api.get(resource, config)
    return response
  },

  async post(resource, data, config) {
    await this.api.cache.clear()
    return await this.api.post(resource, data, config)
  },
}

export default ApiService
export { ApiService, ApiError }
