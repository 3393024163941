import Vue from 'vue'
import Vuex from 'vuex'
import i18n from './i18n'
import EventBus from '../event-bus'

Vue.use(Vuex)

// default locale
const host = window.location.host
//console.log("Hostname: ", host);
const initLocale = host.includes('zijschittert') ? 'nl' : 'en'

export default new Vuex.Store({
  state: {
    lang: initLocale,
    //lang: process.env.VUE_APP_I18N_LOCALE || "nl"
  },
  mutations: {
    switchLang(state) {
      state.lang = state.lang === 'nl' ? 'en' : 'nl'
      i18n.locale = state.lang
      EventBus.$emit('localeChange', state.lang)
    },
  },
  actions: {},
})
