import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
// import Page from "../views/Page";
// import Blog from "../views/Blog";
// import Contact from "../views/Contact";
// import BlogList from "../components/BlogList";
// import BlogItem from "../components/BlogItem";
// const Maintenance = () => import("../views/Maintenance");
const Home = () => import('../views/Home')
const Page = () => import('../views/Page')
const Blog = () => import('../views/Blog')
const Contact = () => import('../views/Contact')
const BlogList = () => import('../components/BlogList')
const BlogItem = () => import('../components/BlogItem')

Vue.use(Router)
Vue.use(Meta)

//const host = window.location.host;
//const parts = host.split(".");
// console.log("Hostname parts: ", parts);
// console.log("VUE_APP_WP_DEFAULT_CATID: ", process.env.VUE_APP_WP_DEFAULT_CATID);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: { id: 34 },
    },
    // {
    //   path: "/",
    //   name: "maintenance",
    //   component: Maintenance
    // },
    {
      path: '/home',
      name: 'home',
      component: Home,
      props: { id: 34 },
    },
    {
      path: '/homeoud',
      name: 'homeoud',
      component: Page,
      props: { id: 34 },
    },
    {
      path: '/about',
      name: 'about',
      component: Page,
      props: { id: 91 },
    },
    // {
    //   path: '/coaching',
    //   name: 'coaching',
    //   component: Page,
    //   props: { id: 5 },
    // },
    {
      path: '/coaching',
      name: 'coaching',
      component: Page,
      props: { id: 506 },
    },
    {
      path: '/blog',
      name: 'blog',
      redirect: '/blog/' + process.env.VUE_APP_WP_DEFAULT_CATID,
      component: Blog,
      children: [
        {
          path: ':catId/:postId',
          name: 'post',
          component: BlogItem,
          props: true,
        },
        {
          path: ':catId',
          name: 'blog-cat',
          component: BlogList,
          props: true,
          // props(route) {
          //   const props = { ...route.params };
          //   props.catId = +props.catId;
          //   return props;
          // }
        },
      ],
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
      props: { id: 121 },
    },
  ],
})
