import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import router from './router'
Vue.use(VueAnalytics, {
  id: 'UA-157954019-1',
  router,
  set: [
    { field: 'anonymizeIp', value: true },
    { field: 'forceSSL', value: true },
  ],
})
